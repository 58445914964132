import axios from 'axios';
import {Auth} from 'aws-amplify';
import {queryMockSuccess} from '../__mocks__/queryMocks';
import {isLocalEnv} from '../utils/env';

axios.interceptors.request.use(async (config) => {
  const currentSession = await Auth.currentSession()
  const jwt = currentSession.idToken.jwtToken;
  config.headers.Authorization = `Bearer ${jwt}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getAPIUrl = () => `${process.env.REACT_APP_API_URL}`

export const query = async (queryString) => {
  // if (isLocalEnv()) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(queryMockSuccess.data);
  //     }, 3000);
  //   });
  // }

  return axios.post(
    `${getAPIUrl()}/query`,
    {q: queryString})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
    });
}


export const getArticle = async (queryData) => {
  const { meta, pageParam, queryKey: [key, path], signal } = queryData;

  return axios.get(`${getAPIUrl()}/path/${encodeURIComponent(path)}`)
    .then(response => {
      console.log(`Get article data (${path}): %o`, response.data);
      return response.data;
    })
    .catch(error => {
      console.error(error);
    });
}
