import React, {useEffect} from 'react';
import {ConfigProvider, message, theme} from 'antd';
import { Amplify } from 'aws-amplify';
import Layout from './components/Layout';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import ro_RO from 'antd/es/locale/ro_RO';
import {MessageProvider} from './hooks/useMessage';
import {AuthProvider} from './context/AuthContext';

Amplify.configure(awsconfig);

const App = ({ signOut, user }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content) => {
    messageApi.open({
      type: 'success',
      content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: 'error',
      content,
    });
  };

  const warning = (content) => {
    messageApi.open({
      type: 'warning',
      content,
    });
  };

  const info = (content) => {
    messageApi.open({
      type: 'info',
      content,
    });
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
      }}
      locale={ro_RO}
    >
      <MessageProvider>
          <AuthProvider>
          {contextHolder}
          <Layout />
          </AuthProvider>
      </MessageProvider>
    </ConfigProvider>
  );
};
export default App;
