import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Card } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import LawsSearchField from "../components/LawsSearchField";
import { query } from "../services/api";
import { LawQueryResult } from "../components/LawQueryResult";
import LawLink from "../components/LawLink";
import { useMessage } from "../hooks/useMessage";

const LawCard = styled(Card)`
  margin-bottom: 30px;
  border: 2px solid #f0f0f0; 
  transition: border-color .25s ease-in-out;

  .ant-card-body {
    min-height: 160px;
    padding: 1.1rem;
  } 

  :hover {
    opacity: 100%;
    border-color: #1677ff6b;
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  min-width: 200px;
  padding: 10px 10px;
  height: 89vh;

  @media (min-width: 1000px) {
    width: 65%;
  }
  @media (max-width: 1000px) {
    width: 90vw;
  }

  ${({ showResult }) => `justify-content: ${showResult ? "start" : "center"};`}
  ${({ showResult }) => `
    justify-content: ${showResult ? "start" : "center"};
    ${showResult ? "" : ""}
  `}
`;

const AnimatedLawsSearch = styled(LawsSearchField)`
  @media (max-width: 980px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Links = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
`;

const Hint = styled.span`
  padding-left: 8px;
  color: #868686;
  
  ${({ hide }) => hide ? "visibility: hidden;" : ""}
`;

const Cards = styled.div`
  overflow: scroll;
`;

function SearchPage({ messages }) {
  const [pageLoading, setPageLoading] = React.useState(false);
  const [results, setResults] = useState([]);
  const { warning, loading, error } = useMessage();

  const format = (strings) => {
    try {
      let text = strings.reduce((acc, str, i, src) => {
        const match = str.match(/(\(ref: )([^()]*)\)/);
        const lawArticlePaths = match?.[2].split(", ") || [];

        if (lawArticlePaths.length) {
          console.log(`> lawArticlePaths: `, lawArticlePaths);
          const links = lawArticlePaths.map((path) => (
            <LawLink asIcon={<FileTextOutlined />} path={path} />
          ));
          const paragraph = str.replace(match[0], "");

          return [...acc, paragraph, <br />, <Links>{links}</Links>];
        }
        return [...acc, str, <br />];
      }, []);

      return text;
    } catch (err) {
      console.error(err);
      warning("Am întâmpinat o problemã, incercați din nou...");

      return ['Am întâmpinat o problemã, incercați din nou...']
    }
  };

  const doSearch = async (value, cb) => {
    try {
      if (!value || !value.length) return;

      setPageLoading(true);
      setResults(prevResults => [{
          value,
          loading: true,
        },
        ...prevResults,
      ]);

      const res = await query(value);

      setResults((prevResults) => prevResults.map((record, i) => {
        if (record.value === value) {
          return {
            ...record,
            loading: false,
            summary: format(res?.summary),
          };
        }

        return record
      }));

      cb();
    } catch (err) {
      error(
        "There was a problem executing your query, please try again. ",
      );

      console.error(err.message);
      console.error(err.stack);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <Page showResult={pageLoading}>
      <Hint>
        Va recomandam sa nu folostiti date cu caracter personal  în formarea
        interogării
      </Hint>
      <AnimatedLawsSearch onSearch={doSearch} loading={pageLoading} />
      <Cards>{results.map((result, index) => (
        <LawCard key={index} loading={result?.loading}>
          <LawQueryResult query={results[index]?.value} content={result?.summary} />
        </LawCard>
      ))}
      </Cards>
    </Page>
  );
}

export default SearchPage;
