import React, {useContext} from 'react';
import {message} from 'antd';

const MessageContext = React.createContext();

export const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content) => {
    messageApi.destroy();

    messageApi.open({
      type: 'success',
      content,
    });
  };

  const error = (content) => {
    messageApi.destroy();

    messageApi.open({
      type: 'error',
      content,
    });
  };

  const warning = (content, configs) => {
    messageApi.destroy();

    messageApi.open({
      type: 'warning',
      content,
      ...configs,
    });
  };

  const info = (content) => {
    messageApi.destroy();

    messageApi.open({
      type: 'info',
      content,
    });
  };

  const loading = (content) => {
    messageApi.destroy();

    messageApi.open({
      type: 'loading',
      content,
    });
  };

  return (
    <MessageContext.Provider value={{ success, error, warning, info, loading }}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};



export const useMessage = () => {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error("useMessage must be used within a MessageProvider");
  }

  return context;
};

