import React from "react";
import {withAuthenticator} from '@aws-amplify/ui-react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';


function LoginPage({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={location.state?.from?.pathname || '/q'} />;
  }

  return (
    <>
      {children}
    </>
  );
}

export default withAuthenticator(LoginPage, {
  hideSignUp: true,
  variation: 'default',
}); // TODO enable signup when plans are avaiable
