import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useAuth} from '../hooks/useAuth';
import {Footer} from 'antd/es/layout/layout';


const Version = styled.span`
  font-size: .65rem;
  display: inline-block;
  right: 51px;
  
  @media (min-width: 980px) {
    position: absolute;
  }
`;

const Gutter = styled.span`
  width: 10px;
  display: inline-block;
`;

function MainFooter(props) {
  const { isAuthenticated} = useAuth();

  return (
    <Footer
      style={{
        textAlign: "center",
        padding: "5px",
        fontSize: "0.85rem",
      }}
    >
      <Link to="/">Acasa</Link>
      <Gutter/>
      {!isAuthenticated && <Link to="/login">Autentificare</Link>}
      {isAuthenticated && <Link to="/q">Cãutare</Link>}
      <Gutter/>
      <Link to="/tos">Termeni și condiții</Link>
      <Gutter/>
      <Link to="/privacy">Confidențialitate</Link>
      <Gutter/>
      <Link to="/contact">Contact</Link>
      <br/>
      AiLege © 2023 | Deferred Labs SRL
      <Version>
        &nbsp;&nbsp;&nbsp;
        Version: {process.env.REACT_APP_GIT_SHA || "Development"}
      </Version>
    </Footer>
  );
}

export default MainFooter;
