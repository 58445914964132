import { Link } from "react-router-dom";

export function NoMatchPage() {
  return (
    <div>
      <h2>Nimic de văzut aici!</h2>
      <p>
        <Link to="/">Întoarceți va la pagina principală</Link>
      </p>
    </div>
  );
}
