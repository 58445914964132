import React from "react";
import {Document, PageTitle} from "./ToSPage";
import { Page } from "./SearchPage";

function ToSPage({ messages }) {
  return (
    <Page>
      <Document>
        <div>
          <PageTitle>Politica de Confidențialitate pentru AILege.ro</PageTitle>
        </div>
        <div>
          <p>
            <strong>Ultima actualizare: 16 Oct 2023</strong>
          </p>
        </div>
        <div>
          <p>
            <strong>1. Introducere</strong>
          </p>
        </div>
        <div>
          <p>
            La AILege.ro, operat de Deferred Labs SRL, respectăm și protejăm
            confidențialitatea vizitatorilor noștri. Această politică descrie
            informațiile pe care le colectăm, cum le folosim și drepturile dvs.
            legate de datele personale pe care ni le furnizați.
          </p>
        </div>
        <div>
          <p>
            <strong>2. Datele pe care le colectăm</strong>
          </p>
        </div>
        <div>
          <p>
            Când utilizați aplicația noastră, putem colecta informații, cum ar
            fi:
          </p>
        </div>
        <div>
          <p>
            a. Datele introduse de dvs. în cadrul interogărilor privind
            legislația.
            <br />
            b. Informații tehnice, inclusiv adresa IP, tipul de dispozitiv,
            browser-ul folosit și alte date legate de sesiunea dvs.
            <br />
            c. Alte informații pe care decideți să ni le furnizați.
          </p>
        </div>
        <div>
          <p>
            <strong>3. Cum folosim datele colectate</strong>
          </p>
        </div>
        <div>
          <p>Utilizăm informațiile colectate în următoarele moduri:</p>
        </div>
        <div>
          <p>
            a. Pentru a răspunde la interogările dvs. legate de legislație.{" "}
            <br />
            b. Pentru a îmbunătăți performanța și funcționalitatea aplicației
            noastre. <br />
            c. Pentru a respecta obligațiile legale și pentru a proteja
            drepturile noastre.
          </p>
        </div>
        <div>
          <p>
            <strong>4. Securitatea datelor</strong>
          </p>
        </div>
        <div>
          <p>
            Ne angajăm să protejăm securitatea datelor dvs. și să luăm măsuri
            adecvate pentru a preveni accesul neautorizat, divulgarea,
            modificarea sau distrugerea datelor dvs.
          </p>
        </div>
        <div>
          <p>
            <strong>5. Conformitatea cu GDPR</strong>
          </p>
        </div>
        <div>
          <p>
            AILege.ro, în calitate de Operator de Date, se conformează
            Regulamentului General privind Protecția Datelor (GDPR) și acordă
            utilizatorilor drepturile sale:
          </p>
        </div>
        <div>
          <p>
            a. <strong>Dreptul la Informare</strong>: Furnizăm această politică
            pentru a vă informa despre colectarea și utilizarea datelor dvs.
            personale.
          </p>
        </div>
        <div>
          <p>
            b. <strong>Dreptul la Acces</strong>: Aveți dreptul să solicitați o
            copie a datelor personale pe care le deținem despre dvs.
          </p>
        </div>
        <div>
          <p>
            c. <strong>Dreptul la Rectificare</strong>: Aveți dreptul să ne
            solicitați să corectăm orice date personale inexacte pe care le
            deținem despre dvs.
          </p>
        </div>
        <div>
          <p>
            d. <strong>Dreptul la Ștergere</strong>: Puteți solicita ștergerea
            datelor dvs. personale, sub rezerva anumitor condiții.
          </p>
        </div>
        <div>
          <p>
            e. <strong>Dreptul de a Limita Prelucrarea</strong>: Aveți dreptul
            de a solicita limitarea prelucrării datelor dvs. personale, sub
            rezerva anumitor condiții.
          </p>
        </div>
        <div>
          <p>
            f. <strong>Dreptul la Portabilitatea Datelor</strong>: Puteți
            solicita să vă transferăm datele pe care vi le-am colectat către
            altă organizație sau direct dvs., sub anumite condiții.
          </p>
        </div>
        <div>
          <p>
            g. <strong>Dreptul de a Obiecta</strong>: Aveți dreptul să vă
            opuneți prelucrării datelor dvs. personale în anumite circumstanțe.
          </p>
        </div>
        <div>
          <p>
            h.{" "}
            <strong>
              Dreptul legat de Deciziile Automatizate și Profilarea
            </strong>
            : Aveți dreptul să nu fiți supus unei decizii bazate exclusiv pe
            prelucrarea automatizată, inclusiv profilarea, care are efecte
            juridice asupra dvs. sau vă afectează în mod similar într-o măsură
            semnificativă.
          </p>
        </div>
        <div>
          <p>
            Dacă doriți să exercitați oricare dintre aceste drepturi sau aveți
            întrebări despre GDPR, vă rugăm să ne contactați la contact&#8197;@&#8197;AiLege.ro.
          </p>
        </div>
      </Document>
    </Page>
  );
}

export default ToSPage;
