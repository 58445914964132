import { useState, useEffect } from 'react';
import {useAuthenticator} from '@aws-amplify/ui-react';

export function useAuth() {
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [isAdminValue, setIsAdminValue] = useState(false);
  const [isAuthenticatedValue, setIsAuthenticatedValue] = useState(false);


  useEffect(() => {
    async function checks() {
      isAdmin()
        .then((res) =>  setIsAdminValue(res))
        .catch(() => setIsAdminValue(false));
      isAuthenticated()
        .then((res) => {
          setIsAuthenticatedValue(res)
        })
        .catch(() => setIsAuthenticatedValue(false));
    }
    checks();
  }, [authStatus, user]);

  const isAuthenticated = async () => {
    return authStatus === 'authenticated';
  }

  const isAdmin = async () => {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    return groups?.includes('Admin');
  }

  return {
    isAdmin: isAdminValue,
    isAuthenticated: isAuthenticatedValue,
    signOut,
    user,
  };
}
