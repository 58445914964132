import React, {useMemo} from 'react';
import {Divider, Dropdown, Space} from 'antd';
import {DownOutlined, MenuOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';


export function MainMenu(factory, deps) {
  const { isAdmin, isAuthenticated, signOut } = useAuth();
  const navigate = useNavigate();

  const items  = useMemo(() => {
    if (!isAuthenticated) return [{
      label: <Link to="/login">Autentificare</Link>,
      key: 0,
    }];

    return [
      {
        label: <Link to="/">Acasã</Link>,
        key: 0,
      },
      {
        label: <Link to="/q">Cautã</Link>,
        key: 1,
      },
      {
        label: <Divider style={{margin: 0}}/>,
        key: 2,
      },
      isAdmin ? {
        label: <Link to="/admin">Admin</Link>,
        key: '3',
      } : null,
      isAdmin ? {
        label: <Divider style={{margin: 0}}/>,
        key: '4',
      } : null,

      {
        label: (
          <Link onClick={signOut}>Sign Out</Link>
        ),
        key: '5',
      },
    ].filter(Boolean)
  }, [isAdmin, isAuthenticated, signOut ]);

  // if (!isAuthenticated) {
  //   return null;
  // }

  const onSignOut = () => {
    signOut();
    navigate('/')
  };

  return (
    <Dropdown menu={{ items }}
      style={{
        width: '100px',
      }}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <MenuOutlined style={{
            fontSize: '15px',
            padding: '0 10px 0 0',
          }} />
        </Space>
      </a>

    </Dropdown>
  );
}

