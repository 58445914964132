import React, {createContext, useContext, useEffect, useState} from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import {Auth} from 'aws-amplify';


const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [jwt, setJWT] = useState(null);

  useEffect(() => {
    checkUser();
    getJWTToken();
  }, []);

  async function getJWTToken() {

    try {
      const session = await Auth.currentSession();
      const jwt = session.getIdToken().getJwtToken();
      setJWT(jwt);
    } catch (error) {
      console.error('Error checking authenticated user', error);
      setCurrentUser(null);
    }
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
    } catch (error) {
      console.error('Error checking authenticated user', error);
      setCurrentUser(null);
    }
  }

  return (
    <Authenticator.Provider>
      <AuthContext.Provider value={{currentUser, jwt, checkUser}}>
        {children}
      </AuthContext.Provider>
    </Authenticator.Provider>

  );
};

// Custom Hook
export const useAuth = () => useContext(AuthContext);
