import React, { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { InfoOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { Button, Space } from "antd";

const StyledInput = styled(TextArea)`
  font-size: 1rem;
  margin-right: 10px;
  
  &:not(.ant-input-disabled) {
    filter: drop-shadow(rgb(0, 21, 41, .5) 4px 3px 0px);
  }
`;

const Wrapper = styled.div`
  
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const isQueryValid = () => {
  const isMinLength = (value) => value.length > 15;

  return isMinLength;
};

const LawsSearchField = ({ loading, onSearch, className }) => {
  const ref = useRef(null);

  const onSearchCallback = useCallback(() => {
    ref.current.resizableTextArea.textArea.textContent = "";
  }, [ref.current]);

  return (
    <>
      <Wrapper className={className}>
        <StyledInput
          ref={ref}
          placeholder="Pentru rezultate cât mai relevante, vă recomandam să formulați interogarea cât mai concisa și clară..."
          // defaultValue="Ce trebuie să conțină o documentație pentru autorizația de construire?"
          disabled={loading}
          autoSize
          onKeyDown={(e) => {
            const value = ref.current.resizableTextArea.textArea.value?.trim();
            if (e.key === "Enter" && isQueryValid(value) && !loading) {
                e.preventDefault();
                onSearch(value, onSearchCallback);
            }
          }}
        />
        <Space />
        {!loading && (
          <Button
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => {
              onSearch(
                ref.current.resizableTextArea.textArea.value,
                onSearchCallback,
              );
            }}
          />
        )}
        {loading && <SyncOutlined spin style={{
          fontSize: '1.8rem',
          color: '#1890ff',
        }} />}
      </Wrapper>
    </>
  );
};

export default LawsSearchField;
