import React from "react";
import {Alert, Image, Space} from 'antd';
import {Flex} from '@aws-amplify/ui-react';
import styled from 'styled-components/macro';
import {Page} from './SearchPage';
import {Document, PageTitle} from "./ToSPage";
import {Link} from 'react-router-dom';

const StyledPage = styled(Page)`
  font-size: 1rem;

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

function HomePage() {
  return (<StyledPage>
    <Flex alignItems="center" justifyContent="space-between">
      <PageTitle>
        AiLege.ro
      </PageTitle>

      <Image preview={false} src="/logo-dark.png" width={100}/>
      <Space direction="horizontal"/>
    </Flex>

    <Document>
      <p id="welcome">
         Bine ați venit pe <b>AiLege.ro</b>, platforma dvs. de navigare simplificată în <i>labirintul legislației românești</i>!
      </p>

      <p id="problema">
        Înțelegem că legislația poate fi complexă și, deseori, intimidantă pentru cei care nu sunt familiarizați cu
        limbajul juridic. Dar nu vă faceți griji! <b>AiLege.ro</b> este aici pentru a face procesul de înțelegere al
        legilor mult mai simplu pentru toată lumea, indiferent dacă sunteți student, jurist, avocat sau simplu
        cetățean.
      </p>

      <p id="cum-ajuta">
        Prin introducerea unei simple interogări, sistemul nostru va căuta și vă va oferi un rezumat clar și concis al
        informației căutate, completat cu referințe directe către articolele de lege relevante. Astfel, veți avea la
        dispoziție toate instrumentele necesare pentru a înțelege ceea ce spune legea, fără să fie nevoie să petreceți
        ore în căutarea și interpretarea textelor de lege.
      </p>

      <p id="de-ce">
        De ce să utilizați AiLege.ro? Pentru că suntem dedicați să punem <i>legislația la îndemâna oricui</i>. Credem
        că
        toată lumea ar trebui să aibă acces la informații juridice clare și comprensibile, fără a fi nevoie de studii
        de specialitate în domeniu.
      </p>

      <p id="alatura-te">
        Vă încurajăm să folosiți formul noastru de înregistrare pentru a vă crea un cont. Odată înregistrat, veți
        primi un e-mail cu detalii pentru a vă loga. Cu un cont pe AiLege.ro, procesul de căutare și
        înțelegere a legilor devine și mai facil și personalizat.
      </p>

      <p>
        Alăturați-vă comunității noastre și descoperiți cum <b>AiLege.ro face legislația accesibilă pentru toată
        lumea!</b>
      </p>
      <br/>

      <Alert message={<>
        <h3>În continuă dezvoltare pentru dvs.</h3>
        <p>
          Produsul nostru este într-o continuă evoluție. La momentul actual, ne-am concentrat eforturile pe
          legislația specifică domeniilor <b> arhitecturii, cadastrului și construcțiilor</b>. Ne dorim să vă oferim o
          resursă completă și de încredere, iar în curând vom extinde gama de legi disponibile. Pentru a fi la
          curent cu ultimele noutăți și pentru a fi înștiințați imediat ce introducem noi legi în platformă, vă
          încurajăm să vă înscrieți. Mulțumim pentru încrederea acordată!
        </p>
      </>}
             type="warning"/>
      <br/>

      <Alert message={<>
        <h3>Înscrieri Temporar Închise</h3>
          <p>Ne dedicăm timp și efort pentru a vă oferi un produs care să corespundă așteptărilor dvs. în cele mai mici
            detalii. De aceea, pentru moment, am decis să suspendăm înscrierile. Totuși, dacă sunteți dornic să aveți
            acces în avans și doriți să fiți printre primii care explorează platforma, vă rugăm să ne contactați prin
            intermediul <Link to={'/contact'}>formularului de contact</Link>. Suntem deschiși la sugestii și la colaborări fructuoase!
          </p></>
      } type="error"/>

    </Document>

  </StyledPage>);
}

export default HomePage;
