import React, { memo, useMemo, useState } from "react";
import {Modal, Popover, Spin, Typography} from "antd";
import { useQuery } from "react-query";
import Link from "antd/es/typography/Link";
import styled from "styled-components/macro";
import { getArticle } from "../services/api";

const PathLink = styled(Link)`
  font-size: 13px;
`;

const PopoverContent = styled(Typography.Text)`
  max-width: 800px;
`;

LawLink.propTypes = {};

function LawLink({ path, asIcon }) {
  const { data = {}, error, isLoading, refetch } = useQuery(
    ["lawArticle", path],
    getArticle,
    { enabled: !!path },
  );
  const [isToggled, setIsToggled] = useState(false);

  const title = useMemo(() => {
    if (isLoading) {
      return "...";
    }
    const { article, chapter, law, section, paragraph, appendix } = data;

    if (!law) {
      console.warn(`Path ${path}, is not correct or there is no record matching it.`);
      return null;
    }

    const lawStr = `Legea ${law || path}`;
    const chapterStr = Number.parseInt(chapter) ? `/ Capitolul ${chapter}` : "";
    const appendixStr = Number.parseInt(appendix) ? `/ Anexa ${appendix}` : "";
    const sectionStr = Number.parseInt(section) ? `/ Sectiunea ${section}` : "";
    const articleStr = Number.parseInt(article) ? `/ Articolul ${article}` : "";
    const paragrahStr = Number.parseInt(paragraph)
      ? `/ Paragraful ${paragraph}`
      : "";

    return `${lawStr}${appendixStr}${chapterStr}${sectionStr}${articleStr}${paragrahStr}`;
  }, [isLoading]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Spin />;
    }
    return <PopoverContent>{data.text}</PopoverContent>;
  }, [isLoading]);

  const refresh = () => {
    if (!data || !data.length) {
      refetch();
    }
  };


  const handleCancel = () => {
    setIsToggled(false);
  }

  return (
    <>
      <Popover
        content={`${data?.text?.substring(0, 100)}...\n Click pentru a o deschide`}
        title={title}
        // open
        showArrow
        placement="bottomRight"
        overlayStyle={{
          maxWidth: '1000px',
          maxHeight: '500px',
          border: '1px solid #01152936',
          borderRadius: '6px',
          filter: 'drop-shadow(rgba(1, 21, 60, .25) 5px 5px 10px)',
          whiteSpace: 'pre-line',
        }}
        trigger="hover"
        onMouseEnter={refresh}
        onClick={() => setIsToggled(!isToggled)}
      >
        {asIcon ? asIcon : <PathLink>{path}</PathLink>}
      </Popover>
      <Modal
        title={title}
        open={isToggled}
        footer={null}
        onCancel={handleCancel}
      >
        {content}
      </Modal>
    </>
  );
}

export default memo(LawLink);
