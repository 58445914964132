import React from "react";
import {Button, Form, Input} from "antd";
import axios from "axios";
import {useMessage} from "../hooks/useMessage";
import styled from 'styled-components/macro';

const WEB3_FORMS_PUBLIC_KEY = "593aed59-bfc2-4b2c-88e3-e93f955a0135";

const Container = styled.div`
  filter: drop-shadow(rgb(0, 21, 41, .4) 4px 3px 0px);
`;

export function ContactForm() {
  const {info, warning, loading} = useMessage();
  const handleSubmit = (values) => {
    loading("Sending...");

    const formData = new FormData();
    formData.append("access_key", WEB3_FORMS_PUBLIC_KEY);
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post("https://api.web3forms.com/submit", formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(async ({data: {message, success}}) => {
        if (success) {
          info(message);
        } else {
          warning(message);
        }
      })
      .catch((error) => {
        console.log(error);
        console.error("There was an error!");
      });
  };

  return (
    <Container>
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="name"
          rules={[{required: true, message: "Vă rugăm să introduceți numele dvs.!"}]}
        >
          <Input placeholder="Nume"/>
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {type: "email", message: "Introducerea nu este un e-mail valid!"},
            {required: true, message: "Vă rugăm să introduceți adresa de e-mail!"},
          ]}
        >
          <Input placeholder="Email"/>
        </Form.Item>

        <Form.Item
          name="company"
          rules={[{message: "Vă rugăm să introduceți mesajul dvs.!"}]}
        >
          <Input placeholder="Companie (opțional)"/>
        </Form.Item>

        <Form.Item
          name="message"
          rules={[{required: true, message: "Vă rugăm să introduceți mesajul dvs.!"}]}
        >
          <Input.TextArea placeholder="Mesaj" rows={4}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Trimite
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
}
