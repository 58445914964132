import React from "react";
import styled from "styled-components/macro";
import {Page} from './SearchPage';
import { ContactForm } from "../components/ContactForm";
import {PageTitle} from './ToSPage';

const Contact = styled.div`
  font-size: 14px;
  margin: 0 auto;
  width: 300px;

`;


function ContactPage() {
  return (
    <Page>
      <Contact>
        <PageTitle>Contact Us</PageTitle>
        <p><strong>Email:</strong> contact&#8197;@&#8197;AiLege.ro</p>
        <p>Created by <strong>DeferredLabs</strong> 🤖</p>
        <br/>

        <ContactForm/>
      </Contact>
    </Page>
);
}

export default ContactPage;
