import React from "react";
import styled from "styled-components/macro";
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import {Image, Layout as AntDLayout, theme, Typography} from "antd";

import SearchPage from "../pages/SearchPage";
import ToSPage from "../pages/ToSPage";
import {NoMatchPage} from "../pages/NoMatchPage";
import LoginPage from "../pages/LoginPage";
import PrivacyPage from "../pages/PrivacyPage";
import {MainMenu} from "./MainMenu";
import ContactPage from '../pages/ContactPage';
import AdminPage from '../pages/AdminPage';
import {RequireAuth} from './RequireAuth';
import HomePage from '../pages/HomePage';
import {useWindowSize} from "@uidotdev/usehooks";
import MainFooter from './MainFooter';


const {Header, Content, Footer} = AntDLayout;
const {Title} = Typography;

const StyledAntDLayout = styled(AntDLayout)`
  height: 100vh;

  .amplify-button[data-variation='primary'] {
    background: linear-gradient(to right,
    rgba(24, 144, 255, 0.9),
    rgba(24, 144, 255, 0.6));
  }

  div[data-amplify-container] {
    filter: drop-shadow(#001529 5px 5px 0px);
  }

  .amplify-button--link {
    color: #1890ff;

    &:hover {
      background: rgba(24, 144, 255, 0.05);
    }
  }
`;

const ContentWrapper = styled(Content)`
  padding: 0 50px;

  @media (max-width: 720px) {
    padding: 0 10px;
  }
`;

const ContentInner = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
`;

const MainTitle = styled(Title)`
  && {
    color: #f5f5f5;
    margin-left: 1rem;
    width: 200px;
  }
`;

const End = styled.div`
  width: 87%;
  display: flex;
  justify-content: flex-end;
`;

const Gutter = styled.span`
  width: 10px;
  display: inline-block;
`;

function Layout() {
  const {
    token: {colorBgContainer},
  } = theme.useToken();
  const size = useWindowSize();


  return (
    <Router>
      <StyledAntDLayout className="layout">
        <Header
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image preview={false}
                 src="/logo.png" style={{
            height: "44px",
            width: "auto",
          }}
                 />
          <Link to="/">
            <MainTitle>
              AiLege
            </MainTitle>
          </Link>{" "}
          <Gutter/>
          <End>
            <MainMenu/>
          </End>
        </Header>

        <ContentWrapper>
          <ContentInner style={{background: colorBgContainer}}>
            <Routes>
              <Route exact path="/login" element={<LoginPage/>}/>
              <Route exact path="/tos" element={<ToSPage/>}/>
              <Route exact path="/privacy" element={<PrivacyPage/>}/>
              <Route exact path="/contact" element={<ContactPage/>}/>

              <Route exact path="/admin" element={<RequireAuth><AdminPage/></RequireAuth>}/>
              <Route exact path="/q" element={<RequireAuth><SearchPage/></RequireAuth>}/>
              <Route exact path="/" element={<HomePage/>}/>

              <Route path="*" element={<NoMatchPage/>}/>
            </Routes>
          </ContentInner>
        </ContentWrapper>

        <MainFooter/>
      </StyledAntDLayout>
    </Router>
  );
}

export default Layout;
