import React from 'react';
import {Page} from './SearchPage';

function AdminPage({ loading }) {
  return (
    <Page showResult={loading}>

    </Page>
  );
}

export default AdminPage;
